const actions = {
    GET_CURRENT_LIVE: "GET_CURRENT_LIVE",
    GET_CURRENT_LIVE_SUCCESS: "GET_CURRENT_LIVE_SUCCESS",
    GET_CURRENT_LIVE_ERROR: "GET_CURRENT_LIVE_ERROR",

    GET_MATCH_LIST: "GET_MATCH_LIST",
    GET_MATCH_LIST_SUCCESS: "GET_MATCH_LIST_SUCCESS",
    GET_MATCH_LIST_ERROR: "GET_MATCH_LIST_ERROR",

    GET_LIVE: "GET_LIVE",
    GET_LIVE_SUCCESS: "GET_LIVE_SUCCESS",
    GET_LIVE_ERROR: "GET_LIVE_ERROR",

    GET_SCORE: "GET_SCORE",
    GET_SCORE_SUCCESS: "GET_SCORE_SUCCESS",
    GET_SCORE_ERROR: "GET_SCORE_ERROR",

    getCurrentLive: (props: any): any => ({
        type: actions.GET_CURRENT_LIVE,
        props,
    }),
    getMatchList: (props: any): any => ({
        type: actions.GET_MATCH_LIST,
        props,
    }),
    getLive: (props: any): any => ({
        type: actions.GET_LIVE,
        props,
    }),
    getScore: (props: any): any => ({
        type: actions.GET_SCORE,
        props,
    }),

};

export default actions;