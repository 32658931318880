// src/redux/slices/liveSlice.ts
import actions from './actions';
export interface S {
  loading: boolean;
  matchlist: any;
  currentLive: any;
  selectLive: any;
  score: any;

}
const initState: S = {
  loading: false,
  matchlist: undefined,
  currentLive: undefined,
  selectLive: undefined,
  score: undefined,
};
function liveReducer(state: S = initState, action: any) {
  switch (action.type) {
    case actions.GET_CURRENT_LIVE:
      return {
        ...state,
        loadiing: true,
      };
    case actions.GET_CURRENT_LIVE_SUCCESS:
      return {
        ...state,
        currentLive: action.data,
        loadiing: false,
      };
    case actions.GET_CURRENT_LIVE_ERROR:
      return {
        ...state,
        loadiing: false,
      };
    case actions.GET_MATCH_LIST:
      return {
        ...state,
        loadiing: true,
      };
    case actions.GET_MATCH_LIST_SUCCESS:
      return {
        ...state,
        matchlist: action.data,
        loadiing: false,
      };
    case actions.GET_MATCH_LIST_ERROR:
      return {
        ...state,
        loadiing: false,
      };
    case actions.GET_LIVE:
      return {
        ...state,
        loadiing: true,
      };
    case actions.GET_LIVE_SUCCESS:
      return {
        ...state,
        selectLive: action.data,
        loadiing: false,
      };
    case actions.GET_LIVE_ERROR:
      return {
        ...state,
        loadiing: false,
      };
    case actions.GET_SCORE:
      return {
        ...state,
        loadiing: true,
      };
    case actions.GET_SCORE_SUCCESS:
      return {
        ...state,
        score: action.data,
        loadiing: false,
      };
    case actions.GET_SCORE_ERROR:
      return {
        ...state,
        loadiing: false,
      };



    default:
      return state;
  }
}


export default liveReducer;
