// src/redux/sagas/rootSaga.ts
import { all } from 'redux-saga/effects';
// Import sagas here
import liveApiSaga from './liveApi/saga'

function* rootSaga() {
    yield all([
        // Add sagas here
        liveApiSaga(),
    ]);
}

export default rootSaga;
