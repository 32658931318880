import { Component } from 'react'
import { BrowserRouter, Navigate, Route } from 'react-router-dom'
import { Routes, } from 'react-router-dom'
import AppLayout from '../layout/AppLayout';
import { RoutesName } from './routeName.constants';
import MainPage from '../pages/MainPage';
import { connect } from 'react-redux';
import MatchViewPage from '../pages/MatchViewPage';
import ScorePage from '../pages/ScorePage';


interface Props {

}
interface State {

}
const manage = "manage";
export class RootRoute extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
    }
    componentDidMount(): void {
    }
    render() {
        return (<BrowserRouter>
            <AppLayout>
                <Routes>
                    <Route path="/" element={<Navigate to={RoutesName.main} />}></Route>
                    <Route path="*" element={<Navigate to={RoutesName.main} />}></Route>
                    <Route path={RoutesName.main} element={<MainPage />}></Route>
                    <Route path={RoutesName.vdo} element={<MatchViewPage />}></Route>
                    <Route path={RoutesName.score} element={<ScorePage />}></Route>
                </Routes>

            </AppLayout>
        </BrowserRouter>
        )
    }

}

const mapState = ({ }: any) => {
    return {

    };
};

export default connect(mapState, {})(RootRoute)
