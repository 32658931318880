// src/redux/sagas/exampleSaga.ts
import {
    takeEvery,
    all,
    delay,
    race,
    fork,
    call,
    put,
} from "redux-saga/effects";
import { message } from "antd";
import actions from "./actions";
import services from "./services";




// eslint-disable-next-line import/no-anonymous-default-export
export default function* () {
    yield all([

    ]);
}
