/* eslint-disable jsx-a11y/alt-text */
import React, { Component, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { VideoCameraOutlined, CalendarOutlined, OrderedListOutlined, CrownOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Card, Col, Modal, Row, Form, Input, Image, Spin, Dropdown, MenuProps } from 'antd';
import { themeColor } from '../assets/theamColor';
import LiveApiAction from '../redux/liveApi/actions'
import axios from 'axios';
import { useIsMobile } from '../services/ScreenSizeContext';
import { sportsData } from '../constants/sportsData';
import sport from '../assets/sport.png'
import casino from '../assets/casino.png'
import slot from '../assets/slot.png'
import luck from '../assets/luck.png'
import vs from '../assets/vs.png'
import line_icon from '../assets/line_icon.png'
import '../assets2/css/style.css'
import '../assets2/css/icon-sport.css'
import LineContact from '../components/LineContact';
import { IResultMatch } from '../interfaces/IResultMatch.interface';


interface Props {
    score: any,
    getScore: Function,

}

const ScorePage: React.FC<Props> = ({ score, getScore }: Props) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [fileLeague, setFileLeague] = useState<string[]>([]);
    const isMobile = useIsMobile();
    const [matchs, setMatchs] = useState<IResultMatch[]>([]);
    let lastDate = '';
    let lastLeague = '';

    const [hasFetched, setHasFetched] = useState(false); // ตัวควบคุม

    useEffect(() => {
        if (!hasFetched) {
            fetchScore(); // เรียก API
            fetchLeague();
            setHasFetched(true); // ตั้งค่าว่าถูกเรียกแล้ว
        }
    }, [hasFetched]); // เพิ่ม dependency `hasFetched` เพื่อควบคุม

    const fetchLeague = async () => {
        try {
            const response = await fetch('https://doball.live/wp-json/custom/v1/get-league-data');
            // const response = await axios.get('https://cdn.soccerclub9.com/check.php');
            const result: string[] = await response.json();
            setFileLeague(parsePrintR(result));
        } catch (error) {
            console.log(error)
        }
    };
    const parsePrintR = (input: any) => {
        // แยกข้อมูลตามบรรทัด
        let lines = input.trim().split('\n');
        if (lines[0].trim() !== 'Array') {
            // ถ้าไม่ใช่ Array ให้คืนค่ากลับ
            return input;
        } else {
            // ลบ "Array" และ "(" และ ")"
            lines.shift(); // "Array"
            lines.shift(); // "("
            lines.pop();   // ")"
            input = lines.join('\n');

            // หาตำแหน่งของคีย์
            const regex = /^\s{4}\[(.+?)\] => /gm;
            const matches = [...input.matchAll(regex)];
            const pos: any = {};
            let previousKey = '';
            const inputLength = input.length;

            // เก็บตำแหน่งของแต่ละคีย์
            matches.forEach((match, index) => {
                const key = match[1];
                const start = match.index + match[0].length;
                pos[key] = [start, inputLength];
                if (previousKey) pos[previousKey][1] = match.index - 1;
                previousKey = key;
            });

            // แปลงข้อมูล
            const result: string[] = new Array(input.length).fill('')
            Object.keys(pos).forEach((key) => {
                const [start, end] = pos[key];
                result[parseInt(key, 10)] = parsePrintR(input.substring(start, end));
            });
            return result;
        }
    };
    const fetchScore = async (_type?: string) => {
        const type = "today";
        try {
            const url = `https://doball.live/wp-json/custom/v1/get-score?type=${type}`;
            const response = await fetch(url);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const rawResult = await response.json();
            const result = typeof rawResult === 'string' ? JSON.parse(rawResult) : rawResult; // แปลงเป็น Object ถ้าเป็น JSON String
            if (result.status === 200) {
                setMatchs(result.data as IResultMatch[])
            } else {
                console.log(result)
            }
            setLoading(false)
        } catch (error) {

        }
    };



    return (
        <div style={{ padding: 10 ,top:40 }}>
            <div style={{ alignContent: 'center', }}>

                <center>
                    <div>
                        {matchs.map((match) => {
                            let dateElement = null;
                            let leagueElement = null;
                            let HomePic = null;
                            let AwayPic = null;

                            if (lastDate !== match.event_date) {
                                dateElement = <div style={{ backgroundColor: "#404040", color: 'white' }}>วันที่ {match.event_date}</div>;
                                lastDate = match.event_date;
                                lastLeague = ''; // รีเซ็ตลีกเมื่อวันที่เปลี่ยนแปลง
                            }

                            if (lastLeague !== match.league_name) {
                                //check symbol - in match,League
                                // const regex = new RegExp(match.League, 'i');
                                const isMatch = fileLeague.some(file => file === `${match.league_name}.png`);
                                if (isMatch) {
                                    leagueElement = (
                                        <div style={{ backgroundColor: "#9b9b9b", color: "white" }} id={match.league_name}>
                                            <img className='pic-league' src={match.league_logo}  ></img>
                                            {match.league_name}
                                        </div>

                                    );
                                } else {
                                    leagueElement = (
                                        <div style={{ backgroundColor: "#9b9b9b", color: "white" }} id={match.league_name}>
                                            <i className='fa-solid fa-trophy'></i>{match.league_name}
                                        </div>

                                    );

                                }

                                lastLeague = match.league_name;
                            }


                            HomePic = <img className="pic-team" src={match.home_team_logo} alt={match.home_team_logo} />
                            AwayPic = <img className="pic-team" src={match.away_team_logo} alt={match.away_team_logo} />
                            // <img className='pic-team' src='https://cdn.soccerclub9.com/img/default.png' ></img>

                            return (
                                <div key={match.event_key} style={{ borderBottom: "1px solid #c0c0c0" }}>
                                    {dateElement}
                                    {leagueElement}
                                    <a href={`/vdo/?matchID=${match.event_key}`} target="_blank" rel="noopener noreferrer" >
                                        <div style={{ color: "#222", backgroundColor: "#e9e9e9" }}>
                                            <div >
                                                <div style={{ width: "100%", display: "flex", flexDirection: 'row', alignItems: 'center', padding: "0px 5px" }}>
                                                    <Row style={{ width: "100%" }}>
                                                        <Col span={4} style={{ display: "flex", flexDirection: 'row', justifyContent: 'center', alignItems: 'center', padding: "0px 5px" }}>
                                                            <span >{match.event_time}</span>

                                                        </Col>
                                                        <Col span={16} style={{ display: "flex", alignItems: 'center', justifyContent: isMobile ? "start" : "center" }}>
                                                            {isMobile ?
                                                                <>
                                                                    <Row >
                                                                        <Col span={24}>
                                                                            <Row style={{ alignItems: 'center' }}>
                                                                                <Col> <div>{HomePic}</div></Col>
                                                                                <Col>  <div><p style={{ width: '100%' }}>{match.event_home_team}</p></div></Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col span={24}>
                                                                            <Row style={{ alignItems: 'center' }}>
                                                                                <Col> <div>{AwayPic}</div></Col>
                                                                                <Col>  <div><p>{match.event_away_team}</p></div></Col>
                                                                            </Row>
                                                                        </Col>

                                                                    </Row>
                                                                </>
                                                                :
                                                                <>
                                                                    <p> {match.event_home_team}</p>
                                                                    {HomePic}
                                                                    {match.event_final_result !== "-" ? <p> {match.event_final_result}</p> : <p style={{ color: "blueviolet" }}>waiting</p>}
                                                                    {AwayPic}
                                                                    <p>{match.event_away_team}</p>
                                                                </>
                                                            }

                                                        </Col>
                                                    </Row>

                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            );

                        }
                        )}
                    </div>

                    <LineContact></LineContact>
                </center >



            </div >

        </div >
    )

}
const { getScore } = LiveApiAction
const mapState = ({ liveApiReducer }: any) => {
    return {
        score: liveApiReducer.score
    };
};
export default connect(mapState, { getScore })(ScorePage)

