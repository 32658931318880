/* eslint-disable jsx-a11y/alt-text */
import React, { Component, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  VideoCameraOutlined,
  CalendarOutlined,
  OrderedListOutlined,
  CrownOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Modal,
  Row,
  Form,
  Input,
  Image,
  Spin,
  Dropdown,
  MenuProps,
} from "antd";
import { themeColor } from "../assets/theamColor";
import LiveApiAction from "../redux/liveApi/actions";
import axios from "axios";
import { useIsMobile } from "../services/ScreenSizeContext";
import { sportsData } from "../constants/sportsData";
import sport from "../assets/sport.png";
import casino from "../assets/casino.png";
import slot from "../assets/slot.png";
import luck from "../assets/luck.png";
import vs from "../assets/vs.png";
import line_icon from "../assets/line_icon.png";
import "../assets2/css/style.css";
import "../assets2/css/icon-sport.css";
import LineContact from "../components/LineContact";
import { RoutesName } from "../routes/routeName.constants";
import { useLocation } from "react-router-dom";
import respin88 from "../assets/image/respin88.gif";
import sixma168 from "../assets/image/sixma168.gif";
import ric168 from "../assets/image/ric168.gif";
import ric789 from "../assets/image/ric789.gif";
import slot666 from "../assets/image/slot666.gif";

interface Props {
  currentLiveApi: any;
  getCurrentLive: Function;
  getLive: Function;
  getMatchList: Function;
}
interface Match {
  Away: string;
  AwayCH: string;
  AwayScore: string;
  AwayTH: string;
  Channel: string;
  Home: string;
  HomeCH: string;
  HomeScore: string;
  HomeTH: string;
  IsLive: string;
  League: string;
  LeagueCH: string;
  LeagueTH: string;
  MatchID: string;
  Name: string;
  NowPlaying: number;
  State: string;
  TimeStart: string;
  TimeStop: string;
  Type: string;
  UTCTimeStart: string;
  UTCTimeStop: string;
}
const MainPage: React.FC<Props> = ({
  currentLiveApi,
  getCurrentLive,
  getLive,
  getMatchList,
}: Props) => {
  const [selectedMatchID, setSelectedMatchID] = useState<string | null>(null); // เก็บ MatchID ที่เลือก
  const [iframeURL, setIframeURL] = useState(""); // เก็บ URL สำหรับ iframe
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [streamUrl, setStreamUrl] = useState("");
  const [matchs, setMatchs] = useState<Match[]>([]);
  const [error, setError] = useState(null);
  const isMobile = useIsMobile();
  const [sportstype, setSportsType] = useState("FOOTBALL");
  const [selectSportType, setSelectSportType] = useState("FOOTBALL");
  const [fileLeague, setFileLeague] = useState<string[]>([]);
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showIframe, setShowIframe] = useState(false);
  const [fullscreenExitButton, setFullscreenExitButton] =
    useState<HTMLButtonElement | null>(null);
  const navigate = useNavigate();
  const [ipAddress, setIpAddress] = useState("");
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const iframeContainerRef = useRef<HTMLDivElement>(null);

  let lastDate = "";
  let lastLeague = "";

  useEffect(() => {
    const fetchStreamUrl = async () => {
      try {
        const response = await fetch(
          "https://doball.live/wp-json/custom/v1/get-random-match-data"
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        // setStreamUrl(result.stream_url);
        if (result.Status === "OK") {
          setStreamUrl(result.H5LINKROW);
        } else {
          // console.log(result)
        }
      } catch (error) {}
    };
    if (!isFullscreen && fullscreenExitButton) {
      // ลบปุ่มเมื่อออกจาก fullscreen
      document.body.removeChild(fullscreenExitButton);
      setFullscreenExitButton(null);
    }
    fetchLeague();
    fetchStreamUrl();
    fetchListMatch();
  }, [isFullscreen, fullscreenExitButton]);

  useEffect(() => {
    if (!selectedMatchID) return; // หากยังไม่มีการเลือก matchID ให้หยุดการทำงาน

    const fetchIpAddress = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        setIpAddress(response.data.ip);
        fetchStreamUrl(response.data.ip);
      } catch (error) {
        console.error("Error fetching the IP address", error);
      }
    };

    const fetchStreamUrl = async (ip: string) => {
      try {
        const response = await fetch(
          `https://doball.live/wp-json/custom/v1/get-match-view-data?ip=${ip}&matchID=${selectedMatchID}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        if (result.Status === "OK") {
          setStreamUrl(result.H5LINKROW);
        } else {
          console.log(result);
        }
      } catch (error) {
        console.error("Error fetching stream URL", error);
      }
    };

    fetchIpAddress();
  }, [selectedMatchID]);

  const fetchListMatch = async (_type?: string) => {
    const type = _type ? _type : sportstype;
    try {
      const response = await fetch(
        "https://doball.live/wp-json/custom/v1/get-match-data?sportstype=" +
          type
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      setMatchs(result.Match);
      setLoading(false);
    } catch (error) {}
  };
  const parsePrintR = (input: any) => {
    // แยกข้อมูลตามบรรทัด
    let lines = input.trim().split("\n");
    if (lines[0].trim() !== "Array") {
      // ถ้าไม่ใช่ Array ให้คืนค่ากลับ
      return input;
    } else {
      // ลบ "Array" และ "(" และ ")"
      lines.shift(); // "Array"
      lines.shift(); // "("
      lines.pop(); // ")"
      input = lines.join("\n");

      // หาตำแหน่งของคีย์
      const regex = /^\s{4}\[(.+?)\] => /gm;
      const matches = [...input.matchAll(regex)];
      const pos: any = {};
      let previousKey = "";
      const inputLength = input.length;

      // เก็บตำแหน่งของแต่ละคีย์
      matches.forEach((match, index) => {
        const key = match[1];
        const start = match.index + match[0].length;
        pos[key] = [start, inputLength];
        if (previousKey) pos[previousKey][1] = match.index - 1;
        previousKey = key;
      });

      // แปลงข้อมูล
      const result: string[] = new Array(input.length).fill("");
      Object.keys(pos).forEach((key) => {
        const [start, end] = pos[key];
        result[parseInt(key, 10)] = parsePrintR(input.substring(start, end));
      });
      return result;
    }
  };
  // ฟังก์ชันที่ใช้แปลงวันที่
  // ฟังก์ชันที่ใช้แปลงวันที่
  const formatDate = (dateString: string, format: string): string => {
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };
    const date = new Date(dateString);
    if (format === "d/m/Y") {
      return date.toLocaleDateString("th-TH", options);
    } else if (format === "H:i") {
      return date.toLocaleTimeString("th-TH", {
        hour: "2-digit",
        minute: "2-digit",
      });
    }
    return dateString;
  };
  // ฟังก์ชันที่ใช้ดึงข้อมูล
  const fetchLeague = async () => {
    try {
      const response = await fetch(
        "https://doball.live/wp-json/custom/v1/get-league-data"
      );
      const result: string[] = await response.json();
      setFileLeague(parsePrintR(result));
    } catch (error) {
      console.log(error);
    }
  };

  const handleFullscreenToggle = () => {
    if (isMobile) {
      if (iframeRef.current) {
        if (isFullscreen) {
          iframeRef.current.style.position = "static";
          iframeRef.current.style.width = "100%";
          iframeRef.current.style.height = isMobile ? "250px" : "500px";
        } else {
          iframeRef.current.style.position = "fixed";
          iframeRef.current.style.top = "0";
          iframeRef.current.style.left = "0";
          iframeRef.current.style.width = "100vw";
          iframeRef.current.style.height = "100vh";
          iframeRef.current.style.zIndex = "9999";

          // เพิ่มปุ่ม "Exit Fullscreen"
          const exitButton = document.createElement("button");
          exitButton.innerText = "Exit Fullscreen";
          exitButton.style.position = "fixed";
          exitButton.style.top = "10px";
          exitButton.style.right = "10px";
          exitButton.style.zIndex = "10000";
          exitButton.style.padding = "10px";
          exitButton.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
          exitButton.style.color = "white";
          exitButton.style.border = "none";
          exitButton.style.borderRadius = "5px";
          exitButton.style.cursor = "pointer";
          exitButton.onclick = () => handleFullscreenToggle();
          document.body.appendChild(exitButton);

          // เก็บปุ่มใน state เพื่อใช้งานตอนออกจาก fullscreen
          setFullscreenExitButton(exitButton);
        }
        setIsFullscreen(!isFullscreen);
      }
    } else {
      if (iframeRef.current) {
        if (!document.fullscreenElement) {
          iframeRef.current
            .requestFullscreen()
            .then(() => setIsFullscreen(true));
        } else {
          document.exitFullscreen().then(() => setIsFullscreen(false));
        }
      }
    }
  };
  const selectSport = (type: string) => {
    setLoading(true);
    fetchListMatch(type);
    setSportsType(type);
    setSelectSportType(type);
  };

  const gradientStyle = {
    fontSize: "64px",
    background: "linear-gradient(90deg, #dcdcdc, #ffffff, #dcdcdc)", // สีเทาและขาว
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    textFillColor: "transparent",
    display: "inline",
  };

  const bodyIcon = {
    borderRadius: 5,
    width: 50,
    height: 50,
    justifyContent: "center",
    alignContent: "center",
  };
  const iconSport = {
    fontSize: 40,
    color: "white",
  };

  const handleMatchClick = (matchID: string) => {
    setSelectedMatchID(matchID);
    setShowIframe(true); // แสดง iframe เมื่อคลิก

    // เลื่อนหน้าไปที่ตำแหน่ง iframe
    setTimeout(() => {
      iframeContainerRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 100); // หน่วงเวลาเล็กน้อยเพื่อให้ iframe แสดงก่อน
  };

  return (
    <div style={{ padding: 10, margin: 30 }}>
      <div style={{ alignContent: "center" }}>
        <center>
          <div
            style={{
              backgroundColor: themeColor.main2,
              padding: 10,
              borderRadius: 10,
              width: isMobile ? "auto" : "80%",
              marginBottom: 10,
            }}
          >
            <div>
              <Row style={{ flexDirection: "column", alignItems: "center" }}>
                <a href="https://www.sixma168.com/">
                <Image src={sixma168} alt="sixma168" style={{marginBottom: 5,cursor: "pointer"}} preview={false}></Image>   
                </a>
                <a href="https://pigusso168.site/">
                <Image src={slot666} alt="slot666" style={{marginBottom: 5,cursor: "pointer"}} preview={false}></Image>   
                </a>
              </Row>
            </div>
            <div>
              <p style={gradientStyle}>ดูบอลออนไลน์ ฟรี 24 ชั่วโมง</p>
              <div style={{ color: "gray" }}>
                <p>
                  มีให้ชมทุกแมตการแข่งขัน [ ดูบอลสดพรีเมียร์ลีกคืนนี้
                  ได้แล้ววันนี้ตลอด 24 ชั่วโมง ],[ ดูบอลลาลีก้า ],[ ดูบอลออนไลน์
                  บุนเดสลีก้า ],[ ดูฟุตบอลโลก ]
                </p>
                <p>
                  และอื่นๆอีกมากมาย
                  การดูบอลออนไลน์ไม่ใช่แค่การติดตามผลการแข่งขันเท่านั้น
                  แต่คุณจะได้สัมผัสกับประสบการณ์ใกล้ชิดจากภาพถ่ายคมชัด{" "}
                </p>
                <p>
                  และคุณภาพเสียงที่ไม่แพ้กับการถ่ายทอดสด
                  เพียงแค่คุณมีการเชื่อมต่ออินเตอร์เน็ต เพียงไม่กี่คลิก
                  เพื่อให้คุณไปถ่ายทำการปิดผู้กระทำปัจจัย
                </p>
              </div>
            </div>
            <div style={{ maxWidth: isMobile ? "100%" : "80%", marginTop: 30 ,marginBottom:30}}>
              <Row style={{ display: "flex", justifyContent: "center" }}>
                <Col
                  xs={3}
                  sm={3}
                  md={2}
                  lg={2}
                  xl={1}
                  style={{ margin: 5, cursor: "pointer" }}
                >
                  <div
                    style={{
                      background:
                        selectSportType === "FOOTBALL" ? "#fc6700" : "#222",
                      ...bodyIcon,
                    }}
                    onClick={() => selectSport("FOOTBALL")}
                  >
                    <i className="iconball-FOOTBALL" style={iconSport}></i>
                  </div>
                </Col>
                <Col
                  xs={3}
                  sm={3}
                  md={2}
                  lg={2}
                  xl={1}
                  style={{ margin: 5, cursor: "pointer" }}
                >
                  <div
                    style={{
                      background:
                        selectSportType === "BASKETBALL" ? "#fc6700" : "#222",
                      ...bodyIcon,
                    }}
                    onClick={() => selectSport("BASKETBALL")}
                  >
                    <i className="iconball-BASKETBALL" style={iconSport}></i>
                  </div>
                </Col>
                <Col
                  xs={3}
                  sm={3}
                  md={2}
                  lg={2}
                  xl={1}
                  style={{ margin: 5, cursor: "pointer" }}
                >
                  <div
                    style={{
                      background:
                        selectSportType === "TENNIS" ? "#fc6700" : "#222",
                      ...bodyIcon,
                    }}
                    onClick={() => selectSport("TENNIS")}
                  >
                    <i className="iconball-TENNIS" style={iconSport}></i>
                  </div>
                </Col>
                <Col
                  xs={3}
                  sm={3}
                  md={2}
                  lg={2}
                  xl={1}
                  style={{ margin: 5, cursor: "pointer" }}
                >
                  <div
                    style={{
                      background:
                        selectSportType === "VOLLEYBALL" ? "#fc6700" : "#222",
                      ...bodyIcon,
                    }}
                    onClick={() => selectSport("VOLLEYBALL")}
                  >
                    <i className="iconball-VOLLEYBALL" style={iconSport}></i>
                  </div>
                </Col>
                <Col
                  xs={3}
                  sm={3}
                  md={2}
                  lg={2}
                  xl={1}
                  style={{ margin: 5, cursor: "pointer" }}
                >
                  <div
                    style={{
                      background:
                        selectSportType === "BOXING" ? "#fc6700" : "#222",
                      ...bodyIcon,
                    }}
                    onClick={() => selectSport("BOXING")}
                  >
                    <i className="iconball-BOXING" style={iconSport}></i>
                  </div>
                </Col>
                <Col
                  xs={3}
                  sm={3}
                  md={2}
                  lg={2}
                  xl={1}
                  style={{ margin: 5, cursor: "pointer" }}
                >
                  <div
                    style={{
                      background:
                        selectSportType === "BADMINTON" ? "#fc6700" : "#222",
                      ...bodyIcon,
                    }}
                    onClick={() => selectSport("BADMINTON")}
                  >
                    <i className="iconball-BADMINTON" style={iconSport}></i>
                  </div>
                </Col>

                <Col
                  xs={3}
                  sm={3}
                  md={2}
                  lg={2}
                  xl={1}
                  style={{ margin: 5, cursor: "pointer" }}
                >
                  <div
                    style={{
                      background:
                        selectSportType === "MMA" ? "#fc6700" : "#222",
                      ...bodyIcon,
                    }}
                    onClick={() => selectSport("MMA")}
                  >
                    <i className="iconball-MMA" style={iconSport}></i>
                  </div>
                </Col>

                <Col
                  xs={3}
                  sm={3}
                  md={2}
                  lg={2}
                  xl={1}
                  style={{ margin: 5, cursor: "pointer" }}
                >
                  <div
                    style={{
                      background:
                        selectSportType === "TABLETENNIS" ? "#fc6700" : "#222",
                      ...bodyIcon,
                    }}
                    onClick={() => selectSport("TABLETENNIS")}
                  >
                    <i className="iconball-TABLETENNIS" style={iconSport}></i>
                  </div>
                </Col>
                <Col
                  xs={3}
                  sm={3}
                  md={2}
                  lg={2}
                  xl={1}
                  style={{ margin: 5, cursor: "pointer" }}
                >
                  <div
                    style={{
                      background:
                        selectSportType === "CRICKET" ? "#fc6700" : "#222",
                      ...bodyIcon,
                    }}
                    onClick={() => selectSport("CRICKET")}
                  >
                    <i className="iconball-CRICKET" style={iconSport}></i>
                  </div>
                </Col>
                <Col
                  xs={3}
                  sm={3}
                  md={2}
                  lg={2}
                  xl={1}
                  style={{ margin: 5, cursor: "pointer" }}
                >
                  <div
                    style={{
                      background:
                        selectSportType === "ESPORTS" ? "#fc6700" : "#222",
                      ...bodyIcon,
                    }}
                    onClick={() => selectSport("ESPORTS")}
                  >
                    <i className="iconball-GAME" style={iconSport}></i>
                  </div>
                </Col>
                <Col
                  xs={3}
                  sm={3}
                  md={2}
                  lg={2}
                  xl={1}
                  style={{ margin: 5, cursor: "pointer" }}
                >
                  <div
                    style={{
                      background:
                        selectSportType === "EFOOTBALL" ? "#fc6700" : "#222",
                      ...bodyIcon,
                    }}
                    onClick={() => selectSport("EFOOTBALL")}
                  >
                    <i className="iconball-GAME" style={iconSport}></i>
                  </div>
                </Col>
                <Col
                  xs={3}
                  sm={3}
                  md={2}
                  lg={2}
                  xl={1}
                  style={{ margin: 5, cursor: "pointer" }}
                >
                  <div
                    style={{
                      background:
                        selectSportType === "EBASKETBALL" ? "#fc6700" : "#222",
                      ...bodyIcon,
                    }}
                    onClick={() => selectSport("EBASKETBALL")}
                  >
                    <i className="iconball-GAME" style={iconSport}></i>
                  </div>
                </Col>
                <Col
                  xs={3}
                  sm={3}
                  md={2}
                  lg={2}
                  xl={1}
                  style={{ margin: 5, cursor: "pointer" }}
                >
                  <div
                    style={{
                      background:
                        selectSportType === "GOLF" ? "#fc6700" : "#222",
                      ...bodyIcon,
                    }}
                    onClick={() => selectSport("GOLF")}
                  >
                    <i className="iconball-GOLF" style={iconSport}></i>
                  </div>
                </Col>
                <Col
                  xs={3}
                  sm={3}
                  md={2}
                  lg={2}
                  xl={1}
                  style={{ margin: 5, cursor: "pointer" }}
                >
                  <div
                    style={{
                      background:
                        selectSportType === "HOCKEY" ? "#fc6700" : "#222",
                      ...bodyIcon,
                    }}
                    onClick={() => selectSport("HOCKEY")}
                  >
                    <i className="iconball-HOCKEY" style={iconSport}></i>
                  </div>
                </Col>
                <Col
                  xs={3}
                  sm={3}
                  md={2}
                  lg={2}
                  xl={1}
                  style={{ margin: 5, cursor: "pointer" }}
                >
                  <div
                    style={{
                      background:
                        selectSportType === "ICEHOCKEY" ? "#fc6700" : "#222",
                      ...bodyIcon,
                    }}
                    onClick={() => selectSport("ICEHOCKEY")}
                  >
                    <i className="iconball-ICEHOCKEY" style={iconSport}></i>
                  </div>
                </Col>
                <Col
                  xs={3}
                  sm={3}
                  md={2}
                  lg={2}
                  xl={1}
                  style={{ margin: 5, cursor: "pointer" }}
                >
                  <div
                    style={{
                      background:
                        selectSportType === "SNOOKER" ? "#fc6700" : "#222",
                      ...bodyIcon,
                    }}
                    onClick={() => selectSport("SNOOKER")}
                  >
                    <i className="iconball-SNOOKER2" style={iconSport}></i>
                  </div>
                </Col>
                <Col
                  xs={3}
                  sm={3}
                  md={2}
                  lg={2}
                  xl={1}
                  style={{ margin: 5, cursor: "pointer" }}
                >
                  <div
                    style={{
                      background:
                        selectSportType === "BASEBALL" ? "#fc6700" : "#222",
                      ...bodyIcon,
                    }}
                    onClick={() => selectSport("BASEBALL")}
                  >
                    <i className="iconball-BASEBALL" style={iconSport}></i>
                  </div>
                </Col>
              </Row>
            </div>
            <div>
              <Row style={{ flexDirection: "column", alignItems: "center" }}>
                <a href="https://www.respin88.com/">
                <Image src={respin88} alt="respin88" style={{marginBottom: 5,cursor: "pointer"}} preview={false}></Image>   
                </a>
                <a href="https://ric168.games/">
                <Image src={ric168} alt="ric168" style={{marginBottom: 5,cursor: "pointer"}} preview={false}></Image>   
                </a>
                <a href="https://ric789.xyz/">
                <Image src={ric789} alt="ric789" style={{marginBottom: 5,cursor: "pointer"}} preview={false}></Image>   
                </a>
              </Row>
            </div>
          </div>
          {/* {isMobile ?
                        <div style={{ maxWidth: 700, minHeight: 300 }}>
                            <iframe
                                ref={iframeRef}
                                id="playframe"
                                src={streamUrl}
                                width="100%"
                                height={250}
                                style={{ border: 'none' }}
                            />
                        </div>
                        :
                        <div style={{ maxWidth: 750, minHeight: 500 }}>
                            <iframe
                                ref={iframeRef}
                                id="playframe"
                                src={streamUrl}
                                width="100%"
                                height={500}
                                style={{ border: 'none' }}
                            />
                        </div>
                    } */}

          <div
            style={{
              backgroundColor: themeColor.main2,
              padding: 10,
              borderRadius: 10,
              width: isMobile ? "auto" : "80%",
              marginBottom: 10,
            }}
          >
            {showIframe ? (
              <div ref={iframeContainerRef}>
                <div style={{ padding: 10 }}>
                  <Button onClick={() => navigate(RoutesName.score)}>
                    ผลบอลวันนี้
                  </Button>
                </div>
                {isMobile ? (
                  <div style={{ maxWidth: 700, minHeight: 300 }}>
                    <iframe
                      ref={iframeRef}
                      id="playframe"
                      src={streamUrl}
                      width="100%"
                      height={250}
                      style={{ border: "none" }}
                    />
                  </div>
                ) : (
                  <div style={{ maxWidth: 750, minHeight: 500 }}>
                    <iframe
                      ref={iframeRef}
                      id="playframe"
                      src={streamUrl}
                      width="100%"
                      height={500}
                      style={{ border: "none" }}
                    />
                  </div>
                )}
                <Button
                  onClick={handleFullscreenToggle}
                  style={{ marginTop: 10, marginBottom: 10 }}
                >
                  {isFullscreen ? "Fullscreen" : "Fullscreen"}
                </Button>
              </div>
            ) : (
              <div></div>
            )}

            <div>
              <div>
                <p style={{ fontSize: 48, color: "white" }}>
                  ตารางถ่ายทอดสด {sportsData[sportstype]}
                </p>
              </div>
            </div>
            <div style={{ backgroundColor: "#e0e0e0", width: "auto" }}>
              {loading ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
                />
              ) : (
                matchs.map((match) => {
                  let dateElement = null;
                  let leagueElement = null;
                  let HomePic = null;
                  let AwayPic = null;
                  const formatDataDisplay = formatDate(
                    match.TimeStart,
                    "d/m/Y"
                  );

                  if (lastDate !== formatDataDisplay) {
                    dateElement = (
                      <div
                        style={{ backgroundColor: "#404040", color: "white" }}
                      >
                        วันที่ {formatDataDisplay}
                      </div>
                    );
                    lastDate = formatDataDisplay;
                    lastLeague = ""; // รีเซ็ตลีกเมื่อวันที่เปลี่ยนแปลง
                  }

                  if (lastLeague !== match.League) {
                    //check symbol - in match,League
                    // const regex = new RegExp(match.League, 'i');
                    const isMatch = fileLeague.some(
                      (file) => file === `${match.League}.png`
                    );
                    if (isMatch) {
                      leagueElement = (
                        <div
                          style={{
                            backgroundColor:
                              match.NowPlaying === 1 ? "#fc6700" : "#9b9b9b",
                            color: "white",
                          }}
                          id={match.League}
                        >
                          <img
                            className="pic-league"
                            src={`https://cdn.soccerclub9.com/img/${match.League}.png`}
                          ></img>
                          {match.League}
                        </div>
                      );
                    } else {
                      leagueElement = (
                        <div
                          style={{
                            backgroundColor:
                              match.NowPlaying === 1 ? "#fc6700" : "#9b9b9b",
                            color: "white",
                          }}
                          id={match.League}
                        >
                          <i className="fa-solid fa-trophy"></i>
                          {match.League}
                        </div>
                      );
                    }

                    lastLeague = match.League;
                  }

                  const isMatchHome = fileLeague.some(
                    (file) => file === `${match.Home}.png`
                  );
                  const isMatchAway = fileLeague.some(
                    (file) => file === `${match.Away}.png`
                  );
                  HomePic = isMatchHome ? (
                    <img
                      className="pic-team"
                      src={`https://cdn.soccerclub9.com/img/${match.Home}.png`}
                      alt={match.Home}
                    />
                  ) : (
                    <img
                      className="pic-team"
                      src="https://cdn.soccerclub9.com/img/default.png"
                    ></img>
                  );
                  AwayPic = isMatchAway ? (
                    <img
                      className="pic-team"
                      src={`https://cdn.soccerclub9.com/img/${match.Away}.png`}
                      alt={match.Away}
                    />
                  ) : (
                    <img
                      className="pic-team"
                      src="https://cdn.soccerclub9.com/img/default.png"
                    ></img>
                  );
                  // <img className='pic-team' src='https://cdn.soccerclub9.com/img/default.png' ></img>

                  return (
                    <div
                      key={match.MatchID}
                      style={{ borderBottom: "1px solid #c0c0c0" }}
                    >
                      {dateElement}
                      {leagueElement}
                      <a
                        // href={`/vdo/?matchID=${match.MatchID}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => handleMatchClick(match.MatchID)} // ตั้งค่า match.MatchID ที่เลือก
                        style={{ cursor: "pointer" }}
                      >
                        <div
                          style={{
                            color: "#222",
                            backgroundColor:
                              match.NowPlaying === 1
                                ? "lightyellow"
                                : "#e9e9e9",
                          }}
                        >
                          <div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                padding: "0px 5px",
                              }}
                            >
                              <Row style={{ width: "100%" }}>
                                <Col
                                  span={4}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "0px 5px",
                                  }}
                                >
                                  <span>
                                    {formatDate(match.TimeStart, "H:i")}
                                  </span>
                                </Col>
                                <Col
                                  span={16}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: isMobile
                                      ? "start"
                                      : "center",
                                  }}
                                >
                                  {isMobile ? (
                                    <>
                                      <Row>
                                        <Col span={24}>
                                          <Row style={{ alignItems: "center" }}>
                                            <Col>
                                              {" "}
                                              <div>{HomePic}</div>
                                            </Col>
                                            <Col>
                                              {" "}
                                              <div>
                                                <p style={{ width: "100%" }}>
                                                  {match.Home}
                                                </p>
                                              </div>
                                            </Col>
                                          </Row>
                                        </Col>
                                        <Col span={24}>
                                          <Row style={{ alignItems: "center" }}>
                                            <Col>
                                              {" "}
                                              <div>{AwayPic}</div>
                                            </Col>
                                            <Col>
                                              {" "}
                                              <div>
                                                <p>{match.Away}</p>
                                              </div>
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                    </>
                                  ) : (
                                    <>
                                      <p> {match.Home}</p>
                                      {HomePic}
                                      {!isMobile && (
                                        <img src={vs} height={28}></img>
                                      )}
                                      {AwayPic}
                                      <p>{match.Away}</p>
                                    </>
                                  )}
                                </Col>

                                <Col
                                  span={4}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    padding: "0px 5px",
                                  }}
                                >
                                  <div className="divTableCell button-play">
                                    <span className="play-fa">
                                      <i
                                        className="fa-solid fa-video"
                                        style={{
                                          color:
                                            match.NowPlaying === 1
                                              ? "red"
                                              : "white",
                                        }}
                                      ></i>
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  );
                })
              )}
            </div>
          </div>

          <div
            style={{
              top: 20,
              backgroundColor: themeColor.main2,
              padding: 10,
              borderRadius: 10,
              width: isMobile ? "auto" : "80%",
              marginBottom: 10,
            }}
          >
            <LineContact></LineContact>
            <div style={{ maxWidth: isMobile ? "100%" : "80%", marginTop: 20 }}>
              <Row style={{ display: "flex", justifyContent: "center" }}>
                <Col
                  xs={3}
                  sm={3}
                  md={2}
                  lg={2}
                  xl={1}
                  style={{ margin: 5, cursor: "pointer" }}
                >
                  <div
                    style={{
                      background:
                        selectSportType === "FOOTBALL" ? "#fc6700" : "#222",
                      ...bodyIcon,
                    }}
                    onClick={() => selectSport("FOOTBALL")}
                  >
                    <i className="iconball-FOOTBALL" style={iconSport}></i>
                  </div>
                </Col>
                <Col
                  xs={3}
                  sm={3}
                  md={2}
                  lg={2}
                  xl={1}
                  style={{ margin: 5, cursor: "pointer" }}
                >
                  <div
                    style={{
                      background:
                        selectSportType === "BASKETBALL" ? "#fc6700" : "#222",
                      ...bodyIcon,
                    }}
                    onClick={() => selectSport("BASKETBALL")}
                  >
                    <i className="iconball-BASKETBALL" style={iconSport}></i>
                  </div>
                </Col>
                <Col
                  xs={3}
                  sm={3}
                  md={2}
                  lg={2}
                  xl={1}
                  style={{ margin: 5, cursor: "pointer" }}
                >
                  <div
                    style={{
                      background:
                        selectSportType === "TENNIS" ? "#fc6700" : "#222",
                      ...bodyIcon,
                    }}
                    onClick={() => selectSport("TENNIS")}
                  >
                    <i className="iconball-TENNIS" style={iconSport}></i>
                  </div>
                </Col>
                <Col
                  xs={3}
                  sm={3}
                  md={2}
                  lg={2}
                  xl={1}
                  style={{ margin: 5, cursor: "pointer" }}
                >
                  <div
                    style={{
                      background:
                        selectSportType === "VOLLEYBALL" ? "#fc6700" : "#222",
                      ...bodyIcon,
                    }}
                    onClick={() => selectSport("VOLLEYBALL")}
                  >
                    <i className="iconball-VOLLEYBALL" style={iconSport}></i>
                  </div>
                </Col>
                <Col
                  xs={3}
                  sm={3}
                  md={2}
                  lg={2}
                  xl={1}
                  style={{ margin: 5, cursor: "pointer" }}
                >
                  <div
                    style={{
                      background:
                        selectSportType === "BOXING" ? "#fc6700" : "#222",
                      ...bodyIcon,
                    }}
                    onClick={() => selectSport("BOXING")}
                  >
                    <i className="iconball-BOXING" style={iconSport}></i>
                  </div>
                </Col>
                <Col
                  xs={3}
                  sm={3}
                  md={2}
                  lg={2}
                  xl={1}
                  style={{ margin: 5, cursor: "pointer" }}
                >
                  <div
                    style={{
                      background:
                        selectSportType === "BADMINTON" ? "#fc6700" : "#222",
                      ...bodyIcon,
                    }}
                    onClick={() => selectSport("BADMINTON")}
                  >
                    <i className="iconball-BADMINTON" style={iconSport}></i>
                  </div>
                </Col>

                <Col
                  xs={3}
                  sm={3}
                  md={2}
                  lg={2}
                  xl={1}
                  style={{ margin: 5, cursor: "pointer" }}
                >
                  <div
                    style={{
                      background:
                        selectSportType === "MMA" ? "#fc6700" : "#222",
                      ...bodyIcon,
                    }}
                    onClick={() => selectSport("MMA")}
                  >
                    <i className="iconball-MMA" style={iconSport}></i>
                  </div>
                </Col>

                <Col
                  xs={3}
                  sm={3}
                  md={2}
                  lg={2}
                  xl={1}
                  style={{ margin: 5, cursor: "pointer" }}
                >
                  <div
                    style={{
                      background:
                        selectSportType === "TABLETENNIS" ? "#fc6700" : "#222",
                      ...bodyIcon,
                    }}
                    onClick={() => selectSport("TABLETENNIS")}
                  >
                    <i className="iconball-TABLETENNIS" style={iconSport}></i>
                  </div>
                </Col>
                <Col
                  xs={3}
                  sm={3}
                  md={2}
                  lg={2}
                  xl={1}
                  style={{ margin: 5, cursor: "pointer" }}
                >
                  <div
                    style={{
                      background:
                        selectSportType === "CRICKET" ? "#fc6700" : "#222",
                      ...bodyIcon,
                    }}
                    onClick={() => selectSport("CRICKET")}
                  >
                    <i className="iconball-CRICKET" style={iconSport}></i>
                  </div>
                </Col>
                <Col
                  xs={3}
                  sm={3}
                  md={2}
                  lg={2}
                  xl={1}
                  style={{ margin: 5, cursor: "pointer" }}
                >
                  <div
                    style={{
                      background:
                        selectSportType === "ESPORTS" ? "#fc6700" : "#222",
                      ...bodyIcon,
                    }}
                    onClick={() => selectSport("ESPORTS")}
                  >
                    <i className="iconball-GAME" style={iconSport}></i>
                  </div>
                </Col>
                <Col
                  xs={3}
                  sm={3}
                  md={2}
                  lg={2}
                  xl={1}
                  style={{ margin: 5, cursor: "pointer" }}
                >
                  <div
                    style={{
                      background:
                        selectSportType === "EFOOTBALL" ? "#fc6700" : "#222",
                      ...bodyIcon,
                    }}
                    onClick={() => selectSport("EFOOTBALL")}
                  >
                    <i className="iconball-GAME" style={iconSport}></i>
                  </div>
                </Col>
                <Col
                  xs={3}
                  sm={3}
                  md={2}
                  lg={2}
                  xl={1}
                  style={{ margin: 5, cursor: "pointer" }}
                >
                  <div
                    style={{
                      background:
                        selectSportType === "EBASKETBALL" ? "#fc6700" : "#222",
                      ...bodyIcon,
                    }}
                    onClick={() => selectSport("EBASKETBALL")}
                  >
                    <i className="iconball-GAME" style={iconSport}></i>
                  </div>
                </Col>
                <Col
                  xs={3}
                  sm={3}
                  md={2}
                  lg={2}
                  xl={1}
                  style={{ margin: 5, cursor: "pointer" }}
                >
                  <div
                    style={{
                      background:
                        selectSportType === "GOLF" ? "#fc6700" : "#222",
                      ...bodyIcon,
                    }}
                    onClick={() => selectSport("GOLF")}
                  >
                    <i className="iconball-GOLF" style={iconSport}></i>
                  </div>
                </Col>
                <Col
                  xs={3}
                  sm={3}
                  md={2}
                  lg={2}
                  xl={1}
                  style={{ margin: 5, cursor: "pointer" }}
                >
                  <div
                    style={{
                      background:
                        selectSportType === "HOCKEY" ? "#fc6700" : "#222",
                      ...bodyIcon,
                    }}
                    onClick={() => selectSport("HOCKEY")}
                  >
                    <i className="iconball-HOCKEY" style={iconSport}></i>
                  </div>
                </Col>
                <Col
                  xs={3}
                  sm={3}
                  md={2}
                  lg={2}
                  xl={1}
                  style={{ margin: 5, cursor: "pointer" }}
                >
                  <div
                    style={{
                      background:
                        selectSportType === "ICEHOCKEY" ? "#fc6700" : "#222",
                      ...bodyIcon,
                    }}
                    onClick={() => selectSport("ICEHOCKEY")}
                  >
                    <i className="iconball-ICEHOCKEY" style={iconSport}></i>
                  </div>
                </Col>
                <Col
                  xs={3}
                  sm={3}
                  md={2}
                  lg={2}
                  xl={1}
                  style={{ margin: 5, cursor: "pointer" }}
                >
                  <div
                    style={{
                      background:
                        selectSportType === "SNOOKER" ? "#fc6700" : "#222",
                      ...bodyIcon,
                    }}
                    onClick={() => selectSport("SNOOKER")}
                  >
                    <i className="iconball-SNOOKER2" style={iconSport}></i>
                  </div>
                </Col>
                <Col
                  xs={3}
                  sm={3}
                  md={2}
                  lg={2}
                  xl={1}
                  style={{ margin: 5, cursor: "pointer" }}
                >
                  <div
                    style={{
                      background:
                        selectSportType === "BASEBALL" ? "#fc6700" : "#222",
                      ...bodyIcon,
                    }}
                    onClick={() => selectSport("BASEBALL")}
                  >
                    <i className="iconball-BASEBALL" style={iconSport}></i>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </center>
      </div>
    </div>
  );
};
const { getCurrentLive, getLive, getMatchList } = LiveApiAction;
const mapState = ({ liveApiReducer }: any) => {
  return {
    currentLiveApi: liveApiReducer.data,
  };
};
export default connect(mapState, { getCurrentLive, getLive, getMatchList })(
  MainPage
);
