import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button, Col, Image, Row } from 'antd';
import axios from 'axios';
import { useIsMobile } from '../services/ScreenSizeContext';
import LiveApiAction from '../redux/liveApi/actions';
import sport from '../assets/sport.png'
import casino from '../assets/casino.png'
import slot from '../assets/slot.png'
import luck from '../assets/luck.png'
import line_icon from '../assets/line_icon.png'
import LineContact from '../components/LineContact';
interface Props {
    currentLiveApi: any,
    getCurrentLive: Function,
    getLive: Function,
    getMatchList: Function,
}

const MatchViewPage: React.FC<Props> = ({ currentLiveApi, getCurrentLive, getLive, getMatchList }: Props) => {
    const [loading, setLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [streamUrl, setStreamUrl] = useState('');
    const [ipAddress, setIpAddress] = useState('');
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const matchID = queryParams.get('matchID');
    console.log(matchID);
    const isMobile = useIsMobile();
    const iframeRef = useRef<HTMLIFrameElement | null>(null);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [fullscreenExitButton, setFullscreenExitButton] = useState<HTMLButtonElement | null>(null);
    useEffect(() => {
        const fetchIpAddress = async () => {
            try {
                const response = await axios.get('https://api.ipify.org?format=json');
                setIpAddress(response.data.ip);
                fetchStreamUrl(response.data.ip);
            } catch (error) {
                console.error('Error fetching the IP address', error);
            }
        };

        if (!isFullscreen && fullscreenExitButton) {
            // ลบปุ่มเมื่อออกจาก fullscreen
            document.body.removeChild(fullscreenExitButton);
            setFullscreenExitButton(null);
        }
        const fetchStreamUrl = async (ip: string) => {
            try {
                const response = await fetch(`https://doball.live/wp-json/custom/v1/get-match-view-data?ip=${ip}&matchID=${matchID}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                if (result.Status === "OK") {
                    setStreamUrl(result.H5LINKROW);
                } else {
                    console.log(result);
                }
            } catch (error) {
                console.error('Error fetching stream URL', error);
            }
        };

        fetchIpAddress();
    }, [matchID, isFullscreen, fullscreenExitButton]);

    const handleFullscreenToggle = () => {
        if (isMobile) {
            if (iframeRef.current) {
                if (isFullscreen) {
                    iframeRef.current.style.position = 'static';
                    iframeRef.current.style.width = '100%';
                    iframeRef.current.style.height = isMobile ? '250px' : '500px';
                } else {
                    iframeRef.current.style.position = 'fixed';
                    iframeRef.current.style.top = '0';
                    iframeRef.current.style.left = '0';
                    iframeRef.current.style.width = '100vw';
                    iframeRef.current.style.height = '100vh';
                    iframeRef.current.style.zIndex = '9999';

                    // เพิ่มปุ่ม "Exit Fullscreen"
                    const exitButton = document.createElement('button');
                    exitButton.innerText = 'Exit Fullscreen';
                    exitButton.style.position = 'fixed';
                    exitButton.style.top = '10px';
                    exitButton.style.right = '10px';
                    exitButton.style.zIndex = '10000';
                    exitButton.style.padding = '10px';
                    exitButton.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
                    exitButton.style.color = 'white';
                    exitButton.style.border = 'none';
                    exitButton.style.borderRadius = '5px';
                    exitButton.style.cursor = 'pointer';
                    exitButton.onclick = () => handleFullscreenToggle();
                    document.body.appendChild(exitButton);

                    // เก็บปุ่มใน state เพื่อใช้งานตอนออกจาก fullscreen
                    setFullscreenExitButton(exitButton);
                }
                setIsFullscreen(!isFullscreen);
            }
        } else {
            if (iframeRef.current) {
                if (!document.fullscreenElement) {
                    iframeRef.current.requestFullscreen().then(() => setIsFullscreen(true));
                } else {
                    document.exitFullscreen().then(() => setIsFullscreen(false));
                }
            }
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }
    return (
        <div style={{}}>
            <div style={{ padding: 10 }}>
                <div style={{ alignContent: 'center' }}>
                    <center>

                        {isMobile ?
                            <div style={{ maxWidth: 700, minHeight: 300 }}>
                                <iframe
                                    ref={iframeRef}
                                    id="playframe"
                                    src={streamUrl}
                                    width="100%"
                                    height={250}
                                    style={{ border: 'none' }}
                                />
                            </div>
                            :
                            <div style={{ maxWidth: 750, minHeight: 500 }}>
                                <iframe
                                    ref={iframeRef}
                                    id="playframe"
                                    src={streamUrl}
                                    width="100%"
                                    height={500}
                                    style={{ border: 'none' }}
                                />
                            </div>
                        }
                        <Button onClick={handleFullscreenToggle} style={{ marginTop: 10 }}>
                            {isFullscreen ? 'Fullscreen' : 'Fullscreen'}
                        </Button>

                        <LineContact></LineContact>
                    </center>
                </div>
            </div>
        </div>
    );
};

const { getCurrentLive, getLive, getMatchList } = LiveApiAction;
const mapState = ({ liveApiReducer }: any) => {
    return {
        currentLiveApi: liveApiReducer.data
    };
};

export default connect(mapState, { getCurrentLive, getLive, getMatchList })(MatchViewPage);
