import React, { useEffect, useState, useRef } from 'react';
import { Button, Col, Image, Row } from 'antd';
import { useIsMobile } from '../services/ScreenSizeContext';
import line_icon from '../assets/line_icon.png'
interface Props {

}

const LineContact: React.FC<Props> = ({ }: Props) => {

    const isMobile = useIsMobile();
    return (

        < a style={{
            flexDirection: "row", backgroundColor: "#00c854", display: "flex",
            alignItems: "center",
            borderRadius: 5, height: 64, width: 200
        }}
            href={`https://line.me/ti/g2/imbgWkyleStS2MU2F3pZ5QvPZVhclxeRmOvuHw?utm_source=invitation&utm_medium=link_copy&utm_campaign=default`} target="_blank" rel="noopener noreferrer"
        >
            <Image src={line_icon} preview={false} style={{ height: 56, width: 56 }}></Image>
            <span style={{ color: "white", }}>ติดตามตารางบอลที่นี่</span>
        </a>
    );
};


export default LineContact
